<template>
  <div>
    <list-kid
      v-if="getKids.length > 0"
      class="kids"
      :kids="getKids"
      title="Tous les enfants"
    />
    <div class="group">
      <list-school-year
        class="school-years"
      />
      <list-settings 
        class="settings"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListKid from '@/components/ListKid.vue'
import ListSchoolYear from '@/components/ListSchoolYear.vue'
import ListSettings from '@/components/ListSettings.vue'

export default {
  name: 'Admin',
  components: {
    ListKid,
    ListSchoolYear,
    ListSettings,
  },
  computed: {
    ...mapGetters('kids', ['getKids']),
  },
  methods: {
    ...mapActions('kids', ['fetchAllKids']),
    ...mapActions('user', ['fetchUsers']),
  },
  created() {
    this.fetchAllKids()
    this.fetchUsers()
  }
}
</script>

<style lang="scss" scoped>
.kids, div.group {
  width: 100%;
  margin: 50px auto;
  max-width: 1100px;
}

.school-years,
.settings {
  width: 100%;
  max-width: 550px;
  margin: 0 auto
}

div.group {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 840px) {
    flex-direction: row;
    justify-content: space-between;

    .school-years,
    .settings {
      width: 50%;
      margin: 0;
    }
  }
}
</style>