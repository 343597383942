<template>
  <div class="modal-container" :class="{ show }">
    <div class="modal create-edit-file">
      <h2 v-if="file">Modifier un fichier</h2>
      <h2 v-else>Envoyer un fichier</h2>
      <font-awesome-icon :icon="['fas', 'times']" class="close" @click="hideModal" />
      <form @submit.prevent="submit">
        <div class="group">
          <label
            for="displayName"
            class="form"
          >Nom d'affichage</label>
          <input
            type="text"
            class="form"
            :class="{ error: inputsError.displayName }"
            id="displayName"
            placeholder="Menu du jour"
            v-model.trim="displayName"
            @change="inputsError.displayName = null"
          />
          <span class="form">{{ inputsError.displayName }}</span>
        </div>
        <div class="group">
          <label
            for="visibility"
            class="form"
          >Visibilité</label>
          <select
            v-model="visibility"
            id="visibility"
            class="form"
          >
            <option :value="true">Visible</option>
            <option :value="false">Non visible</option>
          </select>
        </div>
        <div class="group">
          <label
            for="fileInput"
            class="form"
          >Sélectionner un fichier</label>
          <input
            id="fileInput"
            type="file"
            class="form"
            ref="fileUpload"
            @change="onFileChange"
          />
          <span class="form">{{ inputsError.file }}</span>
        </div>
        <button
          class="form full"
          type="submit"
        >{{ this.file ? 'Modifier' : 'Envoyer' }}</button>
        <button
          class="form full close"
          @click="hideModal"
          @click.prevent
        >Fermer</button>
      </form>
    </div>
  </div>
  
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CreateEditFile',
  props: {
    file: {
      default: null,
    },
  },
  data() {
    return {
      displayName: null,
      visibility: true,
      inputsError: {
        displayName: null,
        visibility: null,
        file: null,
      },
      selectedFile: null,
      show: false,
    }
  },
  computed: {
    
  },
  methods: {
    ...mapActions('files', ['uploadFile']),
    showModal() {
      if (this.file) {
        this.displayName = this.file.displayname
        this.visibility = this.file.visibility
      } else {
        this.displayName = null
        this.visibility = true
      }
      this.selectedFile = null
      this.$refs.fileUpload.value = null
      this.show = true
    },
    hideModal() {
      this.show = false
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.inputsError.file = null
      this.selectedFile = files[0]
    },
    submit() {
      let error = false
      if (!this.displayName || this.displayName.length < 1) {
        this.inputsError.displayName = "Un nom d'affichage est obligatoire"
        error = true
      }

      if (!this.file && !this.selectedFile) {
        this.inputsError.file = 'Un fichier est obligatoire'
        error = true
      }

      if (error)
        return


      let data = {
        displayname: this.displayName,
        visibility: this.visibility
      }

      if (!this.file || this.selectedFile) {
        data.slug = this.displayName.replace(/\s+/g, '-').toLowerCase()
      }

      if (this.selectedFile) {
        const fileName = this.selectedFile.name.split('.')
        data.ext = fileName.splice(-1, 1)[0]
      }

      this.uploadFile({ isExistingFile: this.file ? this.file : null, data, file: this.selectedFile })

      this.hideModal()
    },
  },
}
</script>

<style lang="scss" scoped>
svg.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

form {
  width: 350px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    width: 420px;
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 10px 20px;
  }

  button {
    margin: 10px 0 0;

    &.close {
      margin: 10px 0 0 !important;
      background-color: white;
      color: #3490dc;
      border: 1px solid #3490dc;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    
    @media screen and (min-width: 768px) {
      grid-column: span 2;
    }
  }
}
</style>