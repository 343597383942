<template>
  <div class="settings-container">
    <div class="settings-header">
      <h2>Configuration</h2>
      <export-logs />
    </div>
    <div class="group" v-if="timeLimits">
      <div class="group-input">
        <label
          for="create-limit"
          class="form"
        >Delai d'inscription</label>
        <select
          id="create-limit"
          class="form"
          v-model="timeLimits.create"
          @change="updateLimits('create')"
        >
          <option 
            v-for="i in 5"
            :key="`create-${i}`"
            :value="i"
          >{{ i }}</option>
        </select>
      </div>
      <div class="group-input">
        <label
          for="create-limit"
          class="form"
        >Delai d'annulation</label>
        <select
          id="create-limit"
          class="form"
          v-model="timeLimits.cancel"
          @change="updateLimits('cancel')"
        >
          <option 
            v-for="i in 5"
            :key="`create-${i}`"
            :value="i"
          >{{ i }}</option>
        </select>
      </div>
    </div>
    <div class="files">
      <h3>Fichiers</h3>
      <table>
        <tr>
          <th>Nom du fichier</th>
          <th>Visibilité</th>
          <th>Actions</th>
        </tr>
        <tr>
          <td>Page de connexion/inscription</td>
          <td>
            <input
              class="hidden"
              ref="loginBackgroundInput"
              accept="image/*"
              type="file"
              @change="editLoginBackground"
            />
          </td>
          <td class="action">
            <font-awesome-icon :icon="['fas', 'external-link-alt']" @click="openInNewTab(getLoginBackground)" />
            <font-awesome-icon :icon="['fas', 'edit']" @click="$refs.loginBackgroundInput.click()" />
          </td>
        </tr>
        <tr
          v-for="file in getFiles"
          :key="file.uid"
        >
          <td>{{ file.displayname }}</td>
          <td>
            <font-awesome-icon :icon="['fas', file.visibility ? 'eye' : 'eye-slash']" />
          </td>
          <td class="action">
            <font-awesome-icon :icon="['fas', 'external-link-alt']" @click="openInNewTab(file.url)" />
            <font-awesome-icon :icon="['fas', 'edit']" @click="addEditFile(null, file)" />
            <font-awesome-icon :icon="['fas', 'trash-alt']" @click="delFile(file)" />
          </td>
        </tr>
        <tr>
          <td class="add" colspan="3" @click="addEditFile">Ajouter un fichier</td>
        </tr>
      </table>
    </div>
    <create-edit-file ref="createEditFile" :file="selectedFile" />
  </div>
</template>

<script>
import CreateEditFile from '@/components/CreateEditFile.vue'
import ExportLogs from '@/components/ExportLogs.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListSettings',
  components: {
    CreateEditFile,
    ExportLogs
  },
  data() {
    return {
      timeLimits: null,
      selectedFile: null,
    }
  },
  computed: {
    ...mapGetters('settings', ['getTimeLimits', 'getLoginBackground']),
    ...mapGetters('files', ['getFiles'])
  },
  methods: {
    ...mapActions('settings', ['setTimeLimits', 'updateLoginBackground']),
    ...mapActions('files', ['deleteFile']),
    updateLimits(category) {
      let data = {}
      if (category === 'create') {
        data.create = this.timeLimits.create
      } else if (category === 'cancel') {
        data.cancel = this.timeLimits.cancel
      }

      this.setTimeLimits(data)
    },
    openInNewTab(link) {
      window.open(link)
    },
    addEditFile(_, file = null) {
      this.selectedFile = file
      this.$nextTick(() => {
        this.$refs.createEditFile.showModal()
      })
    },
    delFile(file) {
      if (confirm(`Voulez vous vraiment supprimer "${file.displayname}" ?`)) {
        this.deleteFile(file)
      }
    },
    editLoginBackground(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.updateLoginBackground(files[0])
    }
  },
  created() {
    this.timeLimits = this.getTimeLimits
  }
}
</script>

<style lang="scss" scoped>
div.settings-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  div.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;

    h2 {
      font-size: 20px;
    }

    .button-size {
      height: 36px;
    }
  }

  div.group {
    width: 100%;
    display: flex;
    justify-content: space-between;

    div.group-input {
      width: calc(50% - 10px)
    }
  }

  div.files {
    margin-top: 10px;

    h3 {
      margin-bottom: .5rem;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    table {
      font-family: sans-serif;
      -webkit-font-smoothing: antialiased;
      width: 100%;

      th, td {
        padding: 10px 15px;
        text-align: left;

        &:nth-child(2),
        &:nth-child(3),
        &.add {
          text-align: center;
        }
      }
      
      th {
        background-color: #509edd;
        color: white;
        font-weight: normal;
      }

      td {
        background-color: rgb(238, 238, 238);
        color: rgb(111, 111, 111);

        &.add {
          color: #3490dc;
          cursor: pointer;
        }

        &.action svg {
          cursor: pointer;

          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      input.hidden {
        visibility: hidden;
        position: absolute;
        z-index: -20;
      }
    }
  }

}
</style>