<template>
  <div class="modal-container" :class="{ show }">
    <div class="modal export">
      <h2>Export</h2>
      <font-awesome-icon :icon="['fas', 'times']" class="close" @click="hideModal" />
      <form @submit.prevent="submit">
        <div class="group">
          <label
            for="exportType"
            class="form"
          >Type d'export</label>
          <select
            id="exportType"
            class="form"
            v-model="exportType"
            @change="changeExportType"
          >
            <option value="day">Jour</option>
            <option value="month">Mois</option>
            <option value="year">Année</option>
          </select>
        </div>
        <div class="group">
          <template v-if="exportType === 'day'">
            <label
              for="dayExport"
              class="form"
            >Jour</label>
            <input
              type="date"
              class="form"
              id="dayExport"
              placeholder="05/09/2021"
              v-model.trim="exportValue"
              @change="exportValueError = null"
            />
          </template>
          <template v-if="exportType === 'month'">
            <label
              for="monthExport"
              class="form"
            >Mois</label>
            <select
              id="monthExport"
              class="form"
              v-model="exportValue"
            >
              <option
                v-for="(month, idx) in monthInterval"
                :key="`month-${idx}`"
                :value="month.date"
              >{{ month.text | capitalize }}</option>
            </select>
          </template>
          <span class="form">{{ exportValueError }}</span>
        </div>
        <div class="group">
          <label
            class="form"
          >Classe à exporter</label>
          <div
            class="checkbox"
            v-for="(grade, idx) in grades"
            :key="`grade-${idx}`"
          >
            <input
              type="checkbox"
              class="form"
              :id="`grade-${idx}`"
              v-model="selectedGrades[idx]"
              @change="exportGradesError = null"
            >
            <label class="form" :for="`grade-${idx}`">{{ grade }}</label>
          </div>
          <span class="form">{{ exportGradesError }}</span>
        </div>
        <div class="group">
          <label
            class="form"
          >Créneaux à exporter</label>
          <div
            class="checkbox"
            v-for="(slot, idx) in slots"
            :key="`slots-${idx}`"
          >
            <input
              type="checkbox"
              class="form"
              :id="`slots-${idx}`"
              v-model="slot.enabled"
              @change="exportSlotsError = null"
            >
            <label class="form" :for="`slots-${idx}`">{{ slot.text }}</label>
          </div>
          <span class="form">{{ exportSlotsError }}</span>
        </div>
        <button
          class="form full"
          type="submit"
        >Exporter</button>
        <button
          class="form full close"
          @click="hideModal"
          @click.prevent
        >Fermer</button>
      </form>
    </div>
  </div>
  
</template>

<script>
import { eachMonthOfInterval, format, set, lastDayOfMonth, isBefore, isAfter } from 'date-fns'
import { fr } from 'date-fns/locale'
import { mapMutations } from 'vuex'
import { schoolYearsCollection, kidsCollection } from '@/firebase'
import XLSX from 'xlsx'


export default {
  name: 'Export',
  props: {
    schoolYear: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      grades: [
        'Petite Section',
        'Moyenne Section',
        'Grande Section',
        'CP',
        'CE1',
        'CE2',
        'CM1',
        'CM2',
      ],
      exportType: 'day',
      exportValue: null,
      exportValueError: null,
      exportGradesError: null,
      exportSlotsError: null,
      selectedGrades: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      slots: []
    }
  },
  computed: {
    monthInterval() {
      return this.schoolYear ? eachMonthOfInterval({
        start: new Date(this.schoolYear.start.seconds * 1000),
        end: new Date(this.schoolYear.end.seconds * 1000)
      }).map(date => ({
        text: format(date, 'MMMM', {locale: fr}),
        date: date,
      })) : null
    }
  },
  methods: {
    ...mapMutations('loading', ['setLoading']),
    showModal() {
      this.exportType = 'day'
      this.exportValue = null
      this.exportValueError = null
      this.exportGradesError = null
      this.exportSlotsError = null
      this.slots = []
      this.schoolYear.slots.forEach(slot => {
        this.slots.push({
          ...slot,
          enabled: true
        })
      })
      this.show = true
    },
    hideModal() {
      this.show = false
    },
    changeExportType() {
      this.exportValue = null
      this.exportValueError = null

      if (this.exportType === 'month') {
        const currentMonth = format(new Date(), 'M')
        let found = false
        this.monthInterval.forEach(month => {
          if (format(month.date, 'M') === currentMonth) {
            this.exportValue = month.date
            found = true
          }
        })
        if (!found) {
          this.exportValue = this.monthInterval[0].date
        }
      }
    },
    submit() {
      let kidsFetched = []
      let kidsPromises = []

      let error = false
      if (this.exportType === 'day' && !this.exportValue) {
        this.exportValueError = 'Une date est obligatoire'
        error = true
      }

      let gradeError = true
      this.selectedGrades.forEach(grade => {
        if (grade)
          gradeError = false
      })
      if (gradeError) {
        this.exportGradesError = 'Au moins une classe doit être séléctionné'
        error = true
      }

      let slotError = true
      this.slots.forEach(slot => {
        if (slot.enabled)
          slotError = false
      })
      if (slotError) {
        this.exportSlotsError = 'Au moins un créneau doit être séléctionné'
        error = true
      }

      if (error)
        return

      this.setLoading(true)

      schoolYearsCollection
        .doc(this.schoolYear.uid)
        .collection('days')
        .get()
        .then(querySnapshotDays => {
          let promises = []
          querySnapshotDays.forEach(day => {
            let formattedDay = {
              uid: day.id,
              ...day.data(),
              slots: []
            }

            if (this.exportType === 'day' && format(new Date(formattedDay.date.seconds * 1000), 'yyyy-MM-dd') !== this.exportValue) {
              return
            } else if (this.exportType === 'month') {
              const lastDay = set(lastDayOfMonth(this.exportValue), { hours: 23, minutes: 59, seconds: 59 })
              const dayDate = set(new Date(formattedDay.date.seconds * 1000), { hours: 1 })

              if (isBefore(dayDate, this.exportValue) || isAfter(dayDate, lastDay)) {
                return
              }
            }

            promises.push(new Promise((resolve) => {
              schoolYearsCollection
                .doc(this.schoolYear.uid)
                .collection('days')
                .doc(formattedDay.uid)
                .collection('slots')
                .get()
                .then(querySnapshotSlots => {
                  querySnapshotSlots.forEach(slot => {
                    let formattedSlot = {
                      ...slot.data()
                    }

                    if (formattedSlot.kids.length > 0) {
                      formattedSlot.kids.forEach(kid => {
                        if (!kidsFetched.find(el => el === kid)) {
                          kidsFetched.push(kid)
                          kidsPromises.push(kidsCollection.doc(kid).get())
                        }
                      })
                      formattedDay.slots.push(formattedSlot)
                    }
                  })
                  if (formattedDay.slots.length) {
                    resolve(formattedDay)
                  } else {
                    resolve(null)
                  }
                })
            }))
          })

          Promise.all(promises).then((days) => {
            Promise.all(kidsPromises).then((kids) => {
              days = days.filter(el => el)
              kids = kids.map(el => ({uid: el.id, ...el.data()}))

              if (days.length === 0) {
                this.setLoading(false)
                this.$toasted.error('Aucune donnée disponible.', {
                  position: "bottom-center",
                  icon: {
                    name: 'fas fa-file-export'
                  },
                  duration: 5000
                })
                return
              }

              let rawData = []
              days.forEach(day => {
                day.slots.forEach(slot => {
                  let slotIndex = this.slots.findIndex(el => el.enabled && el.slug === slot.slug)
                  if (slotIndex > -1) {
                    slot.kids.forEach(kid => {
                      kid = kids.find(el => el.uid === kid)
                      if (!this.selectedGrades[kid.grade])
                        return
  
                      rawData.push({
                        date: day.date.seconds,
                        slot,
                        kid
                      })
                    })
                  }
                })
              })

              let seenDays = []

              rawData.forEach(el => {
                const dayIndex = seenDays.findIndex(day => day.date === el.date)
                let day

                if (dayIndex > -1) {
                  day = seenDays[dayIndex]
                  const kidIndex = day.kids.findIndex(kid => kid.kid.uid === el.kid.uid)

                  if (kidIndex > -1) {
                    day.kids[kidIndex].slots.push({
                      name: el.slot.name,
                      slug: el.slot.slug,
                    })
                  } else {
                    day.kids.push({
                      kid: el.kid,
                      slots: [
                        {
                          name: el.slot.name,
                          slug: el.slot.slug,
                        }
                      ]
                    })
                  }
                } else {
                  day = {
                    date: el.date,
                    kids: [
                      {
                        kid: el.kid,
                        slots: [
                          {
                            name: el.slot.name,
                            slug: el.slot.slug,
                          }
                        ]
                      }
                    ]
                  }
                  seenDays.push(day)
                }
              })

              let columnSizes = []
              let rows = []

              let titles = [
                'Date',
                'Classe',
                'Nom',
                'Prénom',
              ]
              this.slots.forEach(slot => {
                if (slot.enabled)
                  titles.push(`${slot.text}`)
              })
              rows.push(titles)

              titles.forEach(el => {
                columnSizes.push(el.length)
              })

              seenDays = seenDays.sort((a, b) => a - b)
              seenDays.forEach(day => {
                day.kids = day.kids.sort((a, b) => {
                  return a.kid.grade - b.kid.grade ||
                    a.kid.lastname.localeCompare(b.kid.lastname, 'fr', { sensitivity: 'base' }) ||
                    a.kid.firstname.localeCompare(b.kid.firstname, 'fr', { sensitivity: 'base' })
                })
                day.kids.forEach(kid => {
                  let row = [
                    `${format(new Date(day.date * 1000), 'dd/MM/yyyy')}`,
                    `${this.grades[kid.kid.grade]}`,
                    `${kid.kid.lastname}`,
                    `${kid.kid.firstname}`,
                  ]
                  this.slots.forEach(slot => {
                    if (slot.enabled) {
                      if (kid.slots.findIndex(kidSlot => kidSlot.slug === slot.slug) > -1) {
                        row.push(1)
                      } else {
                        row.push('')
                      }
                    }
                  })
                  rows.push(row)
                  row.forEach((el, idx) => {
                    if (el.length > columnSizes[idx]) {
                      columnSizes[idx] = el.length
                    }
                  })
                })
              })

              let exportName = ''
              if (this.exportType === 'day') {
                exportName += format(new Date(this.exportValue), 'dd-MM-yyyy')
              } else if (this.exportType === 'month') {
                exportName += format(this.exportValue, 'MMMM', {locale: fr})
              } else {
                exportName += this.schoolYear.uid
              }

              let wb = XLSX.utils.book_new()
              const ws = XLSX.utils.aoa_to_sheet(rows)
              ws['!cols'] = columnSizes.map(el => ({wch: el}))
              XLSX.utils.book_append_sheet(wb, ws, exportName)
              const wopts = { bookType:'xlsx', bookSST:false, type:'array' }
              const wbout = XLSX.write(wb, wopts)
              const blob = new Blob([wbout],{type:"application/octet-stream"})

              let link = document.createElement("a")
              link.setAttribute("href", window.URL.createObjectURL(blob))
              link.setAttribute("class", 'export-link')
              link.setAttribute("download", `export-${exportName}.xlsx`)
              document.body.appendChild(link)

              link.click()

              document.body.removeChild(link)

              this.setLoading(false)
              this.hideModal()
            })
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
svg.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

form {
  width: 200px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    width: 420px;
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 10px 20px;
  }

  button {
    margin: 10px 0 0;

    &.delete {
      margin: 0 0 .5rem;
    }

    &.close {
      margin: 10px 0 0 !important;
      background-color: white;
      color: #3490dc;
      border: 1px solid #3490dc;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    
    @media screen and (min-width: 768px) {
      grid-column: span 2;
    }
  }
}
</style>